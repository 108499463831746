import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboardHomeContainer.scss";
import { dashboardHomeData } from "../../constants/constants";
import { connect } from "react-redux";
import {
  Card,
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
} from "@mui/material";

const DashboardHomeContainer = (props) => {
  const navigate = useNavigate();
  const dashboardData = props.loginResponse.results.totalBlogs;
  return (
    <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
      {dashboardHomeData.map((data, index) => {
        return (
          <Card
            sx={{
              background: data[2],
              width: "20vw",
              borderRadius: "12px",
              height: "20vh",
              cursor: data[0] === "Total Projects" ? "pointer" : "",
              // marginRight: "10vw",
            }}
            onClick={() => {
              navigate(
                data[0] === "Total Projects"
                  ? "/projects"
                  : "",
                { state: { header: data[0] } }
              );
            }}
            key={index}
          >
            <List>
              <ListItem>
                <ListItemText
                  disableTypography
                  sx={{ color: "#7E7E7E", fontSize: "1.25vw" }}
                  primary={data[0]}
                />
                <ListItemIcon>
                  <img src={data[1]} alt={data[1]} />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemText
                  disableTypography
                  sx={{ fontWeight: "900", color: "#23190C", fontSize: "2vw" }}
                >
                  {data[0] === "Total Projects"
                    ? dashboardData
                    : 0}
                </ListItemText>
              </ListItem>
            </List>
          </Card>
        );
      })}
    </ListItem>
  );
};
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
  };
};

export default connect(mapStateToProps, null)(DashboardHomeContainer);
