import React, { useEffect } from "react";
import "./projectListContainer.scss";
import Search from "../search/search";
import { Card, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./projectListContainerStyle";
import placeholder from "../../assets/images/image-placeholder.jpg"

const ProjectListContainer = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);
  const { projectList } = props;

  return (
    <Card sx={{ padding: "2% 0% 3% 0%", margin: "5% 0% 0% 0%" }}>
      <Box className={classes.searchContainer}>
        <Typography sx={{ fontWeight: "bold" }}>PROJECTS</Typography>
        <Button
          onClick={() => {
            navigate("/projects/create");
          }}
          className={classes.newButton}
        >
          <svg width="16" height="26" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.06543 7.50879C5.06543 6.9565 4.61771 6.50879 4.06543 6.50879H1.89697C1.39296 6.50879 0.984375 6.10021 0.984375 5.59619V5.59619C0.984375 5.09218 1.39296 4.68359 1.89697 4.68359H4.06543C4.61771 4.68359 5.06543 4.23588 5.06543 3.68359V1.42285C5.06543 0.913175 5.4786 0.5 5.98828 0.5V0.5C6.49796 0.5 6.91113 0.913175 6.91113 1.42285V3.68359C6.91113 4.23588 7.35885 4.68359 7.91113 4.68359H10.0898C10.5939 4.68359 11.0024 5.09218 11.0024 5.59619V5.59619C11.0024 6.10021 10.5939 6.50879 10.0898 6.50879H7.91113C7.35885 6.50879 6.91113 6.9565 6.91113 7.50879V9.74902C6.91113 10.2587 6.49796 10.6719 5.98828 10.6719V10.6719C5.4786 10.6719 5.06543 10.2587 5.06543 9.74902V7.50879Z" fill="#23190C" />
              </svg>
        </Button>
        <Typography
          sx={{
            fontSize: "0.9vw",
            marginLeft: "2%",
            width: "max-content",
            fontWeight: "bold",
          }}
        >
          Add new need
        </Typography>
        <Box sx={{ marginLeft: "70%", position: "absolute" }}>
          <Search page={props.page} type="blogs" />
        </Box>
      </Box>
      <Box className={classes.listTitleContainer}>
        <Typography sx={{ fontSize: "0.9vw" }} variant="subtitle1">
          Project Title
        </Typography>
        <Typography
          sx={{ fontSize: "0.9vw", marginLeft: "60%", position: "absolute" }}
          variant="subtitle1"
        >
          View Count
        </Typography>
        <Typography
          sx={{ fontSize: "0.9vw", marginLeft: "90%", position: "absolute" }}
          variant="subtitle1"
        >
          Action
        </Typography>
      </Box>
      <Box>
        {projectList &&
          projectList.map((project, index) => {
            if (project) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    padding: "6% 3% 6% 3%",
                    alignItems: "center",
                    position: "relative",
                    borderBottom:"1px solid #EDEDED "
                  }}
                  key={index}
                >
                  {project.videoMedia.length > 0 ? (
                    <video
                      className="project-imagelist"
                      height="84.15px"
                      width="108px"
                      controls
                    >
                      <source
                        src={project.videoMedia[project.videoMedia.length - 1]||placeholder}
                        type="video/webm"
                      />
                    </video>
                  ) : (
                    <img
                      className="project-imagelist"
                      height="84.15px"
                      width="108px"
                      src={project.imageMedia[project.imageMedia.length - 1]||placeholder}
                      alt={project.imageMedia[project.imageMedia.length - 1]||placeholder}
                    ></img>
                  )}
                  <Typography
                    sx={{
                      marginLeft: "13%",
                      textTransform: "uppercase",
                      fontSize: "1vw",
                      width: "20vw",
                      position: "absolute",
                      fontWeight: "600",
                      color: "#23190C",
                    }}
                  >
                    {project.title}
                  </Typography>
                  <Typography sx={{ marginLeft: "62%", position: "absolute",fontWeight:"bold" }}>
                    0
                  </Typography>
                  <Button
                    onClick={() => {
                      navigate("/projects/" + project.b_id, {
                        state: { projectDetails: project },
                      });
                    }}
                    className={classes.viewButton}
                  >
                    VIEW
                  </Button>
                </Box>
              );
            } else {
              return false;
            }
          })}
      </Box>
    </Card>
  );
};

export default ProjectListContainer;
