import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
import "./header.scss";
import Bell from "../../assets/images/bell.png";
import Notifications from "../notifications/notifications";
import profilePlaceholder from "../../assets/images/profilePlaceholder.png";
const Header = (props) => {
  const { loginResponse } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return props.loginResponse.results ? (
    <div className="header-container">
      {console.log(props.loginResponse.results)}
      <div className="header-main">{props.heading}</div>
      <img
        onClick={(e) => {
          handleClick(e);
        }}
        style={{ cursor: "pointer", marginLeft: "70%",position:"absolute" }}
        src={Bell}
        alt={Bell}
      />
      <img onClick={()=>{navigate("/profile")}}
        style={{
          width: "3vw",
          height: "6vh",
          borderRadius: "100%",
          marginLeft: "76%",
          cursor:"pointer",
          position:"absolute"
        }}
        src={
          props.loginResponse.results.userData.profilePic || profilePlaceholder
        }
        alt={
          props.loginResponse.results.userData.profilePic || profilePlaceholder
        }
      />
      {loginResponse ? (
        <Notifications
          open={open}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          loginResponse={loginResponse}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};
const mapStateToProps = (state) => {
  return {
    projectListResponse: state.projectReducer.results,
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch({ type: "LOGOUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
