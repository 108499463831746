import {
  Box,
  Popover,
  Typography,
  Card,
  Tab,
  Tabs,
  Divider,
} from "@mui/material";
import React from "react";
import { notificationStyles } from "./notification.style";
const Notifications = (props) => {
  const classes = notificationStyles();
  const [value, setValue] = React.useState(0);
  const [tabselector, setTabselector] = React.useState(["All"]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {/* {props.loginResponse.results.userData.comments[0].comment} */}
      {props.loginResponse.results ? (
        <Card sx={{ height: "40vh", width: "22vw", p: 2 }}>
          <Box
            display="flex"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography sx={{ fontSize: "1.5vw", fontWeight: "bold" }}>
              Notifications
            </Typography>
            {/* <Typography
              sx={{
                fontWeight: "100",
                fontSize: "0.9vw",
                textDecoration: "underline",
              }}
            >
              Mark all as read
            </Typography> */}
          </Box>
          <Box className={classes.tabContainer}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
            >
              {tabselector && tabselector.map((fields, index) => {
                return (
                  <Tab key={index} className={classes.tab} label={fields} />
                );
              })}
            </Tabs>
          </Box>
          {props.loginResponse.results.userData.comments
            ? props.loginResponse.results.userData.comments.map(
                (data, index) => {
                  return (
                    <React.Fragment>
                      <Box
                        sx={{
                          padding: "5%",
                          background: "rgba(250, 193, 28, 0.09);",
                          // border: "0.5px solid rgba(0, 0, 0, 0.45)",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.9vw", fontWeight: "bold" }}
                        >
                          {data.comment}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.8vw",
                            color: "rgba(35, 25, 12, 0.64);",
                          }}
                        >
                          {data.date}
                        </Typography>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  );
                }
              )
            : ""}
        </Card>
      ) : (
        ""
      )}
    </Popover>
  );
};
export default Notifications;
