import projects from "../assets/images/projects.png";
import donors from "../assets/images/donors.png";
import contributions from "../assets/images/contributions.png";
export const dashboardTabs = [
  "Overall",
  "On Progress",
  "Closing Soon",
  "Finished",
  "Rejected",
];
export const profileTabs = [
  "Account Information",
  "Bank Account Details",
  "Transaction History",
];
export const dashboardTabsNgo = ["NGO", "Donors"];
export const profileTransactionHistory = [
  "Amount",
  "Date",
  "Cause",
  "Mode of payment",
  "Status",
  "Action",
];
export const dashboardProjectHeading = [
  "Need Title",
  "Amount Contributed",
  "Pending Amount",
  "Status",
  "Days Left",
  "Action",
];
export const dashboardNjoHeading = [
  "Name",
  "Phone Number",
  "Email",
  "Status",
  "Action",
];
export const dashboardDonorHeading = [
  "Name",
  "Phone Number",
  "Email",
  "User ID",
  "On boarded date",
  "Status",
  "Action",
];

// export const BASE_URL = "http://localhost:8080/NGO";
export const BASE_URL = "https://ekj-ngo-testing.herokuapp.com/NGO";

export const dashboardHomeData = [
  ["Total Donors", donors, "#F8F9FF"],
  ["Total Contributions", contributions, "#FFF8F8"],
  ["Total Projects", projects, "#FFFEF8"],
];
export const projectDetailsTabs = ["Story", "Update", "Impact"];
export const expImage = "image";
export const expVideo = "video";
export const expAudio = "audio";
export const expFile = "application";
export const rejectBlogMessage = "Confirm Reject and\nenter your feedback";
export const approveBlogMessage = "Please Confirm\nApprove";
export const deleteProjectMessage = "Are you sure you\nwant to delete";
export const blockUserMessage = "Are you sure you\nwant to block";
export const CreateProjectMessage = "Please Confirm\nPost";
export const EditProjectMessage = "Please Confirm\nEdit";
export const UpdateProjectMessage = "Please Confirm\nUpdate";
export const ImpactProjectMessage = "Please Confirm\nImpact";
export const colorPicker = (status) => {
  if (status === "blocked") {
    return "linear-gradient(89.8deg, #DC4734 -4.18%, #EE503C 99.75%)";
  }
  if (status === "verified") {
    return "linear-gradient(89.63deg, #64A91F 1.41%, #79C82A 97.96%)";
  }
  if (status === "pending") {
    return "linear-gradient(270deg, #FAC11C 0%, #DDA400 100%)";
  }
};
export const colorChange = (status) => {
  console.log(status);
  if (status === "Completed" || status === "successfull") {
    return [
      "linear-gradient(89.63deg, #64A91F 1.41%, #79C82A 97.96%)",
      "/completed.png",
    ];
  } else if (
    status === "In progress" ||
    status === "pending" ||
    status === undefined ||
    status === "created"
  ) {
    return [
      "linear-gradient(270deg, #FAC11C 0%, #DDA400 100%)",
      "/inprogress.png",
    ];
  } else if (status === "Closing") {
    return ["#679FF4", "/closing.png"];
  } else if (
    status === "rejected" ||
    status === "Refund" ||
    status === "refunded"
  ) {
    return [
      "linear-gradient(89.8deg, #DC4734 -4.18%, #EE503C 99.75%)",
      "/rejected.png",
    ];
  }
};
export const approveSettlementMessage = "Please Confirm\nSettleMent";
export const goalData = [
  "No Poverty",
  "Zero Hunger",
  "Good Health and Well-being",
  "Quality Education",
  "Gender Equality",
  "Clean Water and Sanitation",
  "Affordable and Clean Energy",
  "Decent Work and Economic Growth",
  "Industry, Innovation and Infrastructure",
  "Reduced Inequality",
  "Sustainable Cities and Communities",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life on Land",
  "Peace and Justice Strong Institutions",
  "Partnerships to achieve the Goal",
];