import "./App.css";
import Registration from "./pages/registration/registration.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login.jsx";
import { history } from "./helpers/history";
import React from "react";
import Dashboard from "./pages/dashboard/dashboardHome/dashboardHome";
import Projects from "./pages/projects/listProjects/listProjects";
import MediaLibrary from "./pages/medialibrary/mediaLibrary";
import NewsArticles from "./pages/newsarticles/newsArticles";
import Profile from "./pages/profile/profile";
import CreateProject from "./pages/projects/createProject/createProject";
import DashboardDetails from "./pages/dashboard/dashboardDetails/dashboardDetails";
import ProjectDetails from "./pages/projects/projectDetails/projectDetails";
import UpdateProject from "./pages/projects/updateProject/updateProject";
import CreateImpactProject from "./pages/projects/createImpactProject/createImpactProject";
import pagenotfound from "./assets/images/404.json";
import Lottie from "react-lottie";
import ForgotPassword from "./pages/login/forgotPassword";
import ResetPassword from "./pages/login/resetPassword";
import EnterOtp from "./pages/login/enterOtp";
import CreateNews from "./pages/newsarticles/createNews";
import NewsDetails from "./pages/newsarticles/newsDetails";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: pagenotfound,
  renderer: "svg",
};

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <BrowserRouter history={history}>
          {history.location.pathname === "/" ? history.push("/login") : ""}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/medialibrary" element={<MediaLibrary />} />
            <Route path="/newsarticles" element={<NewsArticles />} />
            <Route path="/newsarticles/create" element={<CreateNews />} />
            <Route path="/newsArticles/:id" element={<NewsDetails />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="*"
              element={
                <div>
                  <Lottie options={defaultOptions} height={500} width={500} />
                </div>
              }
            />{" "}
            <Route path="/Projects" element={<Projects />} />
            <Route path="/projects/create" element={<CreateProject />} />z
            <Route path="/projects/update" element={<UpdateProject />} />
            <Route path="/projects/impact" element={<CreateImpactProject />} />
            <Route path="/dashboard/details" element={<DashboardDetails />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/enterOtp" element={<EnterOtp />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
