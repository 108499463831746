import React, { useRef, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { goalData } from "../../constants/constants";
import {
  Card,
  Typography,
  Box,
  Button,
  Input,
  Select,
  Chip,
  MenuItem,
  FormControl
} from "@mui/material";
import { ngoProfileStyles } from "./ngoProfileStyles";
import { connect } from "react-redux";
import "./profileContainer.scss";
import { Theme, useTheme } from "@mui/material/styles";

import { DatePicker } from "../datepicker/datepicker";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, goal, theme) {
  return {
    fontWeight:
      goal.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const AccountInformation2 = (props) => {
  const theme = useTheme();
  const classes = ngoProfileStyles();
  const inputRef = React.createRef();
  const [goal, setGoals] = useState(
    props.profileDetails.goals && props.profileDetails.goals.length != 0 ? props.profileDetails.goals : []
  );

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(typeof(value),value)
    props.profileDetails.goals = typeof value === "string" ? value.split(",") : value;
    props.setProfileDetails(props.profileDetails);
    setGoals(
      typeof value === "string" ? value.split(",") : value
    );
    
  };
  return (
    <Card sx={{ borderTopLeftRadius: "0px", padding: "1%" }}>
      <Typography
        style={{ fontSize: "1.5vw", fontWeight: "600", marginLeft: "2vw" }}
      >
        Basic details:
      </Typography>
      <Box
        style={{
          display: "grid",
          margin: "5% 0% 1% 0%",
          padding: "0% 0% 0% 3%",
          alignItems: "center",
        }}
      >
        <Typography className={classes.title}>Date of Incoporation*</Typography>
        <DatePicker
          ref={inputRef}
          style={{
            width: "23vw",
            margin: "1% 0% 1% 0%",
            background: "#FFFFFF",
            border: "1px solid #CACACA",
            borderRadius: 6,
            color: "black",
            fontSize: "1vw",
            textTransform: "initial",
          }}
          className={`${classes.text} ${classes.input}`}
          value={
            props.profileDetails.dateOfIncoporation == null
              ? ""
              : props.profileDetails.dateOfIncoporation.toString().split("T")[0]
          }
          onChange={(e) => {
            props.handleChange(e, "dateOfIncoporation");
          }}
        />
        <Typography className={classes.title}>
        Pan Card No
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.pancard}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "pancard");
          }}
        />
        <Typography className={classes.title}>
          F.C.R.A Registration Number(optional)*
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.registrationNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "registrationNumber");
          }}
        />
        <Typography className={classes.title}>G.S.T Number*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.gstNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "gstNumber");
          }}
        />

        <Typography className={classes.title}>
          Accounts Head Name(optional)*
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.accountHeadName}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "accountHeadName");
          }}
        />
        <Typography className={classes.title}>
          Regional office Address(optional)*
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.regionalOfficeAddress}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "regionalOfficeAddress");
          }}
        />
        <Typography className={classes.title}>
          80G Registration(Exemption from IT for Donors)*
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.taxRegistration}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "taxRegistration");
          }}
        />
        <Typography className={classes.title}>
          12AA/AB Certification*
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.AAABCertification}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "AAABCertification");
          }}
        />
        <Typography className={classes.title}>
          Accounts Head Contact Number(optional)*
        </Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.accountsHeadContact}
          type="number"
          onChange={(e) => {
            props.handleChange(e, "accountsHeadContact");
          }}
        />
        <Typography className={classes.title}>SDG Goal*</Typography>
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={goal}
            onChange={handleChangeSelect}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {goalData.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, goal, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div display="inline">
          <Button
            type="submit"
            onClick={(e) => {
              props.handlePrevious(e);
            }}
            className={classes.NgoSaveButton}
            style={{ width: "15vw", marginLeft: "0vw", marginTop: "5%" }}
          >
            Back
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              props.handleNext(e);
            }}
            className={classes.NgoSaveButton}
            style={{ width: "15vw", marginLeft: "42vw", marginTop: "5%" }}
          >
            Next
          </Button>
        </div>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    profilePictureResponse: state.registerReducer.profilePicture,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (profileDetails) =>
      dispatch({ type: "EDIT_USER", value: profileDetails }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformation2);
