import React from "react";
import {
  Box,
  TableCell,
  TableRow,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,

} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import more from "../../assets/images/more.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import deleteImage from "../../assets/images/delete-menu.png";
import DeleteImage from "../../assets/images/delete-image.png";
import DialogBox from "../dialogBox/confirmationDialog";
import { deleteProjectMessage,colorChange } from "../../constants/constants";
import { useStyles } from "./dashboardDetailsStyle";
import placeholder from "../../assets/images/image-placeholder.jpg";


let title = "";
let image = "";
let status = "";
const ProjectStatusContainer = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [projectData, setProjectData] = useState();
  const [feedback, setFeedback] = useState("");
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setProjectData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    status = "";
    if (modal) {
      setModal(!modal);
      props.deleteProject(projectData.b_id);
      let timer = setTimeout(() => {
        props.listAllProjects(1,"project");
        navigate("/projects");
      }, 200);
      return () => clearTimeout(timer);
    } else {
      image = DeleteImage;
      setModal(!modal);
      title = deleteProjectMessage;
    }
  };
  const { data } = props;
  return (
    <TableRow
      key={data.title}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <DialogBox
        status={status}
        feedback={feedback}
        setFeedback={setFeedback}
        submit={status !== "" ? "" : handleDelete}
        handleDelete={handleDelete}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
     <TableCell
        onClick={() => {
          navigate("/projects/" + data.b_id, {
            state: { projectDetails: data },
          });
        }}
        sx={{
          display: "flex",
          fontSize: "0.9vw",
          cursor: "pointer",
          width: "24vw",
        }}
        align="left"
      >
        <img
          style={{ borderRadius: "0%", marginRight: "5%" }}
          width="90vw"
          height="70vh"
          src={data.imageMedia[0]||placeholder}
          alt={data.imageMedia[0]||placeholder}
        />
        <Box sx={{ display: "grid", rowGap: "1px" }}>
          <ListItemAvatar
            disableTypography
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "1vw",
              width: "20vw",
            }}
          >
            {data.title}
          </ListItemAvatar>
          <ListItemText
            disableTypography
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#717171",
              fontSize: "0.9vw",
            }}
          >
            {data.uniqueId}
          </ListItemText>
          <ListItemText
            disableTypography
            sx={{
              fontWeight: "bold",
              color: "#000000",
              fontSize: "0.9vw",
            }}
          >
            {data.lastModified}
          </ListItemText>
        </Box>
      </TableCell>
      {/* <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>{data.authorName}</strong>
      </TableCell> */}
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>0</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong>0</strong>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <Box
          sx={{
            background: colorChange(
              data.status === "rejected" ? data.status : data.diffDays
            )[0],
            borderRadius: "30px",
            textTransform: "uppercase",
            color: "#ffffff",
            textAlign: "center",
            padding: "3% 0% 3% 0%",
            alignItems: "center",
            display: "flex",
            width: "9vw",
          }}
        >
          <img
            style={{
              borderRadius: "100%",
              marginRight: "5%",
              marginLeft: "5%",
            }}
            // src={"/" + data.diffDays + ".png"}
            src={
              colorChange(
                data.status === "rejected" ? data.status : data.diffDays
              )[1]
            }
            alt={
              colorChange(
                data.status === "rejected" ? data.status : data.diffDays
              )[1]
            }
          />
          {data.status === "rejected" ? data.status : data.diffDays}
        </Box>
      </TableCell>
      <TableCell
        sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
        align="left"
      >
        <strong> {data.daysLeft < 0 ? 0 : data.daysLeft} days</strong>
      </TableCell>
      <TableCell align="left">
        <img
          style={{ cursor: "pointer" }}
          onClick={handleClick}
          src={more}
          alt={more}
        />
        <Menu className={classes.menu}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          autoFocus={false}
        >
          <MenuItem
            sx={{ fontSize: "1vw" }}
            onClick={() => {
              setAnchorEl(null);
              handleDelete();
            }}
          >
            <ListItemIcon>
              <img src={deleteImage} alt={deleteImage} />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};
export default ProjectStatusContainer;
