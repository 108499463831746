import {combineReducers,createStore,applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import registerReducer from "../redux/reducer/registerReducer"
import loginReducer from "./reducer/loginReducer";
import projectReducer from "./reducer/projectReducer";
import rootSaga from "./sagas";
import transactionReducer from "./reducer/transactionReducer";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const appReducer = combineReducers({
    registerReducer:registerReducer,
    loginReducer:loginReducer,
    projectReducer:projectReducer,
    transactionReducer:transactionReducer
})
const rootReducer = (state,action) => {
    if(action.type === "LOGOUT") {
        state = undefined
    }
    return appReducer(state,action)
}
const store = createStore(rootReducer, {}, applyMiddleware(...middleware));
sagaMiddleware.run(rootSaga);

export default store;