import React, { useState, useEffect } from "react";
import "../projectDetailsContainer/projectDetailsContainer.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useLocation } from "react-router-dom";
import {
  projectDetailsTabs,
  rejectBlogMessage,
  approveBlogMessage,
  deleteProjectMessage,
} from "../../constants/constants";
import backButton from "../../assets/images/back.svg";
import { connect } from "react-redux";
import approve from "../../assets/images/approve.png";
import reject from "../../assets/images/reject.png";
import edit from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";
// import roundaddbtn from "../../assets/images/roundadd.png";
import { Document, Page, pdfjs } from "react-pdf";
import DialogBox from "../dialogBox/confirmationDialog";
import rejectImage from "../../assets/images/reject-image.png";
import approveImage from "../../assets/images/approve-image.png";
import DeleteImage from "../../assets/images/delete-image.png";
import { Card, Box, Button, Typography } from "@mui/material";
import { useStyles } from "../projectDetailsContainer/projectDetailsContainerStyle";
import MediaPlayer from "../mediaPlayer/mediaPlayer";
import PdfViewer from "../pdfViewer/pdfViewer";
import imagePlaceholder from "../../assets/images/image-placeholder.jpg";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
let status = "";
let title = "";
let image = "";
let fileSelected = "";
function createMarkup(description) {
  return { __html: description };
}

const NewsDetailsContainer = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [pdfmodal, setPdfModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  let docs = [{ uri: "", fileType: "" }];
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const location = useLocation();
  const { projectDetails } = location.state;
  const projectStatusChange = (action) => {
    if (modal) {
      setModal(!modal);
      projectDetails.status = status;
      projectDetails.feedback = feedback;
      props.projectStatusChange(projectDetails);
      let timer = setTimeout(() => {
        props.listAllProjects(1, "news");
      }, 200);
      return () => clearTimeout(timer);
    } else {
      if (action === "approved") {
        image = approveImage;
        title = approveBlogMessage;
      } else {
        image = rejectImage;
        title = rejectBlogMessage;
      }
      status = action;
      setModal(!modal);
    }
  };

  const handleDelete = () => {
    status = "";
    if (modal) {
      setModal(!modal);
      props.deleteProject(projectDetails.b_id);
      let timer = setTimeout(() => {
        props.listAllProjects(1,"news");
        navigate("/newsArticles");
      }, 200);
      return () => clearTimeout(timer);
    } else {
      image = DeleteImage;
      setModal(!modal);
      title = deleteProjectMessage;
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    if (props.projectDetailsResponse.results || props.upload1Response.results) {
      props.removeDetails();
    }
  }, [navigate, props]);

  return (
    <Card
      sx={{
        borderRadius: "21px",
        border: "1px solid #EBEBEB",
        margin: "3% 2% 1% 2%",
        paddingBottom: "5%",
      }}
    >
      {modal ? (
        <DialogBox
          status={status}
          feedback={feedback}
          setFeedback={setFeedback}
          submit={status !== "" ? projectStatusChange : handleDelete}
          handleDelete={handleDelete}
          image={image}
          title={title}
          modal={modal}
          setModal={setModal}
        />
      ) : (
        ""
      )}
      <Box
        style={{
          display: "flex",
          margin: "1% 0% 1% 0%",
          padding: "0% 0% 0% 3%",
          alignItems: "center",
          borderBottom: "1px solid #EDEDED",
        }}
      >
        <img
          onClick={() => {
            navigate("/newsArticles");
          }}
          style={{ cursor: "pointer", width: "1.25vw" }}
          src={backButton}
          alt={backButton}
        />
        <Box
          style={{
            margin: "2% 3% 2% 2%",
            fontSize: "1.25vw",
            display: "flex",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          <Typography sx={{ width: "max-content" }}>NEWS</Typography>
        </Box>
        <Box
          className={
            projectDetails.status === "approved"
              ? classes.approveStatus
              : classes.rejectStatus
          }
        >
          {projectDetails.status}
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", margin: "1% 1% 1% 3%" }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.5vw",
            fontWeight: "bold",
          }}
        >
          {projectDetails.title}
        </Typography>

        <Box sx={{ marginLeft: "67%", display: "flex", position: "absolute" }}>
          <img
            style={{ cursor: "pointer" }}
            height="22vh"
            width="22vw"
            src={edit}
            alt={edit}
            onClick={() => {
              navigate("/newsArticles/create", {
                state: { editprojectDetails: location.state.projectDetails },
              });
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img
            style={{ cursor: "pointer" }}
            height="22vh"
            width="22vw"
            onClick={() => {
              handleDelete();
            }}
            src={deleteIcon}
            alt={deleteIcon}
          />
        </Box>
      </Box>

      <Box
        sx={{
          margin: "3% 0% 0% 3%",
        }}
      >
        <Typography
          sx={{ fontSize: "0.9vw", fontWeight: "bold", marginBottom: "3%" }}
        >
          {projectDetails.createdTime}
        </Typography>
        <img
          style={{
            borderRadius: "8px",
            width: "35vw",
            height: "40vh",
            cursor: "pointer",
          }}
          src={
            projectDetails.imageMedia[projectDetails.imageMedia.length - 1] ||
            imagePlaceholder
          }
          alt={
            projectDetails.imageMedia[projectDetails.imageMedia.length - 1] ||
            imagePlaceholder
          }
        />
        <div
          dangerouslySetInnerHTML={createMarkup(projectDetails.description)}
        ></div>
      </Box>
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    upload1Response: state.projectReducer.upload1,
    upload2Response: state.projectReducer.upload2,
    updateResponse: state.projectReducer.update,
    impactResponse: state.projectReducer.impact,
    projectDetailsResponse: state.projectReducer.projectNewDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteProject: (id) => dispatch({ type: "DELETE_PROJECT", value: id }),
    projectStatusChange: (data) =>
      dispatch({ type: "CHANGE_PROJECT_STATUS", value: data }),
    listAllProjects: (page, type) =>
      dispatch({ type: "PROJECT_LIST", value: { page: page, type: type } }),
    removeDetails: () =>
      dispatch({ type: "REMOVE_UPDATE_IMPACT_PROJECT_ASYNC" }),
    getProjectUpdateOrImpact: (tab, data) =>
      dispatch({
        type: "GET_PROJECT_UPDATE_IMPACT",
        value: { data: data, tab: tab },
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsDetailsContainer);
