import { useStyles } from "./loginStyle.js";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import "../login/login.scss";
import loginbckimg from "../../assets/images/Loginview2.png";
import { useNavigate } from "react-router-dom";
import spinner from "../../assets/images/loadingSpinner.json";
import Lottie from "react-lottie";
import logo from "../../assets/images/logo3.png";

import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Snackbar,
  Checkbox,
} from "@mui/material";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.forgotPasswordResponse.message === "New otp added") {
      navigate("/enterOtp");
    } else if (
      props.forgotPasswordResponse.message === "Email is not correct"
    ) {
      setError(true);
    }
  }, [props, error]);
  const [email, setEmail] = useState("");
  const classes = useStyles();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.loadingMedia(true);
    props.forgotPassword(email);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundImage: "url(" + loginbckimg + ")",
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "cover",
          backgroundSize: "100vw 100vh",
        }}
      ></Box>
      <Box
        sx={{ position: "absolute", left: "35%", top: "8%", minWidth: "29%" }}
      >
        <img
          width="35%"
          height="35%"
          style={{ margin: "0% 0% 7% 32%" }}
          src={logo}
          alt={logo}
        />
        <Typography
          sx={{ fontWeight: "500", marginBlock: "3%", fontSize: "38px" }}
          align="center"
          variant="h4"
        >
          Forgot Password?
        </Typography>
        <Typography align="center" variant="subtitle1">
          Please enter your Email ID
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            error={error}
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={email}
            onChange={(e) => {
              props.logOut();
              setError(false);
              setEmail(e.target.value);
            }}
            placeholder="Email ID"
            fullWidth
            style={{ marginTop: 20, border: "0" }}
            disableUnderline={true}
          />

          {error ? (
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                marginTop: "5%",
                color: "#FA2E2E",
                borderRadius: "55px",
              }}
            >
              {props.forgotPasswordResponse.message}
            </Typography>
          ) : (
            ""
          )}
          <Button type="submit" fullWidth className={classes.submitButton}>
            Generate OTP
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    forgotPasswordResponse: state.registerReducer.forgotPassword,
    loadingMediaResponse: state.projectReducer.loadingMedia,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) =>
      dispatch({
        type: "FORGOT_PASSWORD",
        value: email,
      }),
    logOut: () => dispatch({ type: "LOGOUT" }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
