import { useStyles } from "./loginStyle.js";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import "../login/login.scss";
import loginbckimg from "../../assets/images/Loginview2.png";
import { useNavigate, useLocation } from "react-router-dom";
import spinner from "../../assets/images/loadingSpinner.json";
import Lottie from "react-lottie";
import logo from "../../assets/images/logo3.png";

import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
const ResetPassword = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  useEffect(() => {
    if (props.resetPasswordResponse.result) {
      navigate("/login");
      props.logOut();
    }
  }, [props]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      props.resetPassword(location.state.email, password);
    } else {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundImage: "url(" + loginbckimg + ")",
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "cover",
          backgroundSize: "100vw 100vh",
        }}
      ></Box>
      <Box sx={{ position: "absolute", left: "35%", top: "8%" }}>
        <img
          width="35%"
          height="35%"
          style={{ margin: "0% 0% 7% 32%" }}
          src={logo}
          alt={logo}
        />
        <Typography
          sx={{ fontWeight: "500", marginBlock: "3%" }}
          align="center"
          variant="h4"
        >
          Reset Password
        </Typography>
        <Typography align="center" variant="subtitle1">
          Please enter your new Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            type="password"
            error={error}
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            onChange={(e) => {
              setError(false);
              setPassword(e.target.value);
            }}
            placeholder="New Password"
            fullWidth
            style={{ marginTop: 20, border: "0" }}
            disableUnderline={true}
          />

          <TextField
            type="password"
            error={error}
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw" } }}
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => {
              setError(false);
              setConfirmPassword(e.target.value);
            }}
            placeholder="Confirm Password"
            style={{ marginTop: 20, border: "0" }}
            disableUnderline={true}
          />
          {error ? (
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                marginTop: "5%",
                color: "#FA2E2E",
                borderRadius: "55px",
              }}
            >
              {props.resetPasswordResponse.message}
            </Typography>
          ) : (
            ""
          )}
          <Button type="submit" fullWidth className={classes.submitButton}>
            Submit
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordResponse: state.registerReducer.resetPassword,
    verifyOtpResponse: state.registerReducer.verifyOtp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email, password) =>
      dispatch({
        type: "RESET_PASSWORD",
        value: { email: email, password: password },
      }),
    logOut: () => dispatch({ type: "LOGOUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
