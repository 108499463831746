const initialState = {
  results: {},
  profilePicture: "",
  forgotPassword: "",
  verifyOtp: "",
  resetPassword: "",
};

const registerReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "REGISTERASYNC":
      newState.results = action.value;
      break;
    case "PROFILE_UPLOAD_ASYNC":
      newState.profilePicture = action.value;
      break;
    case "FORGOT_PASSWORD_ASYNC":
      newState.forgotPassword = action.value;
      break;
    case "VERIFY_OTP_ASYNC":
      newState.verifyOtp = action.value;
      break;
    case "RESET_PASSWORD_ASYNC":
      newState.resetPassword = action.value;
      break;
    default:
      return newState;
  }
  return newState;
};

export default registerReducer;
