import React from "react";
import DashboardHomeContainer from "../../../components/dashboardHomeContainer/dashboardHomeContainer";
import Header from "../../../components/header/header";
import Navbar from "../../../components/navbar/navbar";
import PieChartView from "../../../components/piechart/pieChartView";
import gifReader from "../../../assets/images/loading.json";
import Lottie from "react-lottie";
import { List, ListItem, ListItemText, Card } from "@mui/material";

import { connect } from "react-redux";
import BarChartGraph from "../../../components/barChart/barChart";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};

const DashboardHome = (props) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (!token || token == "undefined") {
      navigate("/login");
    } else if (!props.loginResponse.results) {
      props.auth();
    }
  }, [props]);

  return token !== "undefined" ? (
    <div>
      <Navbar active="Dashboard" />

      <Header heading="HOME" />
      {props.loginResponse.results ? (
        <Card
          sx={{
            margin: "0% 0% 0% 12%",
            padding: "1% 2% 9% 4%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
          <DashboardHomeContainer />
          <ListItem sx={{ marginBottom: "3%" }}>
            <List>
              <ListItemText sx={{ margin: "1% 1% 3% 0%" }}>
                <strong>Donation Status</strong>
              </ListItemText>
              <BarChartGraph />
            </List>
            <List>
              <ListItemText sx={{ margin: "1% 1% 3% 30%" }}>
                <strong>Projects</strong>
              </ListItemText>
              <PieChartView
                actionResponse={props.actionResponse}
                dashboardAction={props.dashboardAction}
                authResponse={props.loginResponse.results}
                projectTabAction={props.projectTabAction}
                selectedTab={props.selectedTab}
              />
            </List>
          </ListItem>
        </Card>
      ) : (
        <Lottie options={defaultOptions} height={500} width={500} />
      )}
    </div>
  ) : (
    ""
  );
};
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
    actionResponse: state.projectReducer.dashboardAction,
    selectedResponse: state.projectReducer.selectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auth: () => dispatch({ type: "AUTH" }),
    dashboardAction: (action) =>
      dispatch({ type: "DASHBOARD_ACTION", value: action }),
    projectTabAction: (action) =>
      dispatch({ type: "PROJECT_TAB", value: action }),
    selectedTab: (action) => dispatch({ type: "SELECTED_TAB", value: action }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
