import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  loginButton: {
    marginTop: "0vh",
    background: "#FAC11C",
    borderRadius: "12px",
    textTransform: "capitalize",
    color: "black",
    padding: "2.5%",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
  },
  loginInput: {
    border: "1px solid #CACACA",
    borderRadius: "6px",
    background: "#FFFFFF",
    "& .Mui-error": {
      color: "red",
    },
  },
  registerButton: {
    background: "#F5F5FA",
    borderRadius: "12px",
    marginTop: "5vh",
    marginBottom:"5vh",
    border: "1px solid #DCDCEB",
    color: "black",
    padding: "2.5%",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#F5F5FA",
      opacity: 0.9,
    },
  },

  submitButton: {
    marginTop: "5vh",
    background: "#FAC11C",
    borderRadius: "12px",
    textTransform: "capitalize",
    color: "black",
    padding: "2.5%",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
  }
});
