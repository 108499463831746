import React, { useState } from "react";
import { connect } from "react-redux";
import { Component } from 'react';
import {
  Card,
  Typography,
  Box,
  Button,
  Input,
  Select,
  MenuItem,
} from "@mui/material";


import countryAndRegion from 'country-region-data/data.json';

import { ngoProfileStyles } from "./ngoProfileStyles";
import { countries } from "../../assets/countries";
import { states } from "../../assets/states";

import "./profileContainer.scss";
const AccountInformation = (props) => {
  const classes = ngoProfileStyles();
  const [value, setValue] = useState(props.profileDetails.contactNumber);
  const handleUpload = (event) => {
    props.loadingMedia(true);
    props.profileUpload(event.target.files[0]);
  };
  return (
    <Card sx={{ borderTopLeftRadius: "0px", padding: "1%" }}>
      <Typography
        style={{ fontSize: "1.2vw", fontWeight: "600", marginLeft: "2vw" }}
      >
        Basic details:
      </Typography>
      <Box
        style={{
          display: "grid",
          margin: "5% 0% 1% 0%",
          padding: "0% 0% 0% 3%",
          alignItems: "center",
        }}
      >
        <Typography className={classes.title}>Name*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.name}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "name");
          }}
        />
        <Typography className={classes.title}>Email Address*</Typography>
        <Input
          style={{}}
          disabled
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.emailAddress}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "emailAddress");
          }}
        />
        <Typography className={classes.title}>Type of NGO*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.typeOfNGO}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "typeOfNGO");
          }}
        />
        <Typography className={classes.title}>Address*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.address}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "address");
          }}
        />
        <Typography className={classes.title}>City*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.city}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "city");
          }}
        />
        <Typography className={classes.title}>Pin Code*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.pinCode}
          type="number"
          onChange={(e) => {
            props.handleChange(e, "pinCode");
          }}
        />
        <Typography className={classes.title}>Country*:</Typography>
        <Select
          className={`${classes.text} ${classes.input}`}
          value={props.profileDetails.country}
          onChange={(e) => {
            props.handleChange(e, "country");
          }}
          size="small"
        >
          <MenuItem value="default">{props.profileDetails.country}</MenuItem>
          {countryAndRegion &&
            countryAndRegion.map((country) => (
              <MenuItem value={country.countryName}>
                {country.countryName}&nbsp;&nbsp;&nbsp;({country.countryShortCode})
              </MenuItem>
            ))}
        </Select>
        <Typography className={classes.title}>State*</Typography>
        <Select
          className={`${classes.text} ${classes.input}`}
          value={props.profileDetails.state}
          onChange={(e) => {
            props.handleChange(e, "state");
          }}
          size="small"
        >
          <MenuItem value="default">{props.profileDetails.state}</MenuItem>
          {countryAndRegion &&
            countryAndRegion[countryAndRegion.map(e => e.countryName).indexOf(props.profileDetails.country) == -1 ? 102 : countryAndRegion.map(e => e.countryName).indexOf(props.profileDetails.country)].regions.map((state) => (
              <MenuItem value={state.name}>
                {state.name}
              </MenuItem>
            ))}
        </Select>
        <Typography className={classes.title}>Contact Number*</Typography>
        <Input
          typeof="number"
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.contactNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "contactNumber");
          }}
        />
        {/* <Typography className={classes.title}>Country*:</Typography>
        <Select
          className={`${classes.text} ${classes.input}`}
          value={props.profileDetails.country}
          onChange={(e) => {
            props.handleChange(e, "country");
          }}
          size="small"
        >
          <MenuItem value="default">{props.profileDetails.country}</MenuItem>
          {countries &&
            countries.map((country, code) => (
              <MenuItem value={country.name}>
                {country.name}&nbsp;&nbsp;&nbsp;({country.code})
              </MenuItem>
            ))}
        </Select> */}
        <Typography className={classes.title}>Founders Name*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.foundersName}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "foundersName");
          }}
        />
        <Typography className={classes.title}>Founders Contact Number</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.founderContactNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "founderContactNumber");
          }}
        />
        <Typography className={classes.title}>Focussed Causes*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder=""
          disableUnderline={true}
          value={props.profileDetails.focusedCourses}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "focusedCourses");
          }}
        />
        <Button
          type="submit"
          onClick={(e) => {
            props.handleNext(e);
          }}
          className={classes.NgoSaveButton}
          style={{ width: "15vw", marginLeft: "57vw", marginTop: "5%" }}
        >
          Next
        </Button>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    profilePictureResponse: state.registerReducer.profilePicture,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (profileDetails) =>
      dispatch({ type: "EDIT_USER", value: profileDetails }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
