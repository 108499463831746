import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";

function* registerAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: BASE_URL + "/signup",
        data: {
          name: request.value.name,
          email: request.value.email,
          address: request.value.address,
          city: request.value.city,
          state: request.value.state,
          contactNumber: request.value.contactNumber,
          registrationNumber: request.value.registrationNumber,
          password: request.value.password,
          type: "NGO",
        },
      });
    });
    yield put({ type: "REGISTERASYNC", value: data });
  } catch (e) {
    alert("user cannot be registered");
    console.log(e.message);
  }
}
function* verifyOtpAsync(request) {
  try {
    const { data } = yield call(() => {
      console.log(request);
      return axios.request({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: BASE_URL + "/forgotPassword/verifyOTP",
        data: {
          otp: request.value,
        },
      });
    });
    yield put({ type: "VERIFY_OTP_ASYNC", value: data });
  } catch (e) {
    alert("user cannot be registered");
    console.log(e.message);
  }
}
function* resetPasswordAsync(request) {
  try {
    const { data } = yield call(() => {
      console.log(request);
      return axios.request({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: BASE_URL + "/resetPassword",
        data: {
          password: request.value.password,
          email: request.value.email,
        },
      });
    });
    yield put({ type: "RESET_PASSWORD_ASYNC", value: data });
  } catch (e) {
    alert("user cannot be registered");
    console.log(e.message);
  }
}

function* editUserAsync(request) {
  console.log("ERquest", request.value);
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "put",
        url: BASE_URL + "/editUser",
        data: {
          id: request.value._id,
          name: request.value.name,
          age: request.value.age,
          pancard: request.value.pancard,
          emailAddress: request.value.emailAddress,
          country: request.value.country,
          location: request.value.location,
          gender: request.value.gender,
          address: request.value.address,
          city: request.value.city,
          pinCode: request.value.pinCode,
          state: request.value.state,
          contactNumber: request.value.contactNumber,
          registrationNumber: request.value.registrationNumber,
          status: request.value.status,
          type: request.value.type,
          profilePic: request.value.profilePicture,
          typeOfNGO: request.value.typeOfNGO,
          foundersName: request.value.foundersName,
          nameOfAccountHolder: request.value.nameOfAccountHolder,
          accountNumber: request.value.accountNumber,
          ifscCode: request.value.ifscCode,
          branch: request.value.branch,
          gpayNumber: request.value.gpayNumber,
          paytmNumber: request.value.paytmNumber,
          accountsHeadName: request.value.accountsHeadName,
          accountsHeadContact: request.value.accountsHeadContact,
          communucationHeadName: request.value.communucationHeadName,
          communucationHeadContact: request.value.communucationHeadContact,
          title: request.value.title,
          dateOfIncorporation: request.value.dateOfIncorporation,
          fcraRegNumber: request.value.fcraRegNumber,
          focusedCourses: request.value.focusedCourses,
          gstNumber: request.value.gstNumber,
          regionalOfficeAddress: request.value.regionalOfficeAddress,
          taxRegistration: request.value.taxRegistration,
          AAABCertification: request.value.AAABCertification,
          facebookId: request.value.facebookId,
          instagramId: request.value.instagramId,
          bankName: request.value.bankName, 
          goals: request.value.goals,
          certificates: request.value.certificates
        },
      });
    });
    yield put({ type: "LOADING_MEDIA", value: false });
  } catch (e) {
    alert("user cannot be registered");
    console.log(e.message);
  }
}

function* profileUploadAsync(request) {
  try {
    const formData = new FormData();
    formData.append("file", request.value);
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          "content-type": "multipart/form-data",
        },
        method: "post",
        url: BASE_URL + "/blogs/upload",
        data: formData,
      });
    });
    yield put({ type: "LOADING_MEDIA", value: false });
    yield put({ type: "PROFILE_UPLOAD_ASYNC", value: data });
  } catch (e) {
    alert("could not upload. Please try again");
  }
}

export function* watchRegister() {
  yield takeLatest("REGISTER", registerAsync);
  yield takeLatest("PROFILE_UPLOAD", profileUploadAsync);
  yield takeLatest("EDIT_USER", editUserAsync);
  yield takeLatest("VERIFY OTP", verifyOtpAsync);
  yield takeLatest("RESET_PASSWORD", resetPasswordAsync);
}
