import React, { useState, useEffect } from "react";
import { profileTabs } from "../../constants/constants";
import Tabs from "../tabs/tabs";
import "./profileContainer.scss";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import AccountInformation from "./accountInformation";
import BankAccountDetails from "./bankAccountDetails";
import TransactionHistory from "./transactionHistory";
import AccountInformation2 from "./accountInformation2";
import AccountInformation3 from "./accountInformation3";
import { EditProjectMessage } from "../../constants/constants";
import approveImage from "../../assets/images/approve-image.png";
import DialogBox from "../dialogBox/confirmationDialog";

let title = "";
let image = "";
const ProfileContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [tabValue, setTabValue] = useState(
    (location.state && location.state.tabValue) || "Account Information"
  );
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate, props]);
  const tabChange = (tab) => {
    setTabValue(tab);
    setPage(1);
  };
  const { registeredFields } = props;
  console.log("---->", registeredFields);
  const [profileDetails, setProfileDetails] = useState({
    name: registeredFields.name,
    emailAddress: registeredFields.emailAddress,
    typeOfNgo: registeredFields.typeOfNGO,
    address: registeredFields.address,
    city: registeredFields.city,
    pinCode: registeredFields.pinCode,
    country: registeredFields.country,
    state: registeredFields.state,
    contactNumber: registeredFields.contactNumber,
    foundersName: registeredFields.foundersName,
    focusedCourses: registeredFields.focusedCourses,
    dateOfIncorporation: registeredFields.dateOfIncorporation,
    pancard: registeredFields.pancard,
    registrationNumber: registeredFields.registrationNumber,
    gstNumber: registeredFields.gstNumber,
    accountHeadName: registeredFields.accountHeadName,
    regionalOfficeAddress: registeredFields.regionalOfficeAddress,
    taxRegistration: registeredFields.taxRegistration,
    AAABCertification: registeredFields.AAABCertification,
    accountsHeadContact: registeredFields.accountsHeadContact,
    communucationHeadName: registeredFields.communucationHeadName,
    communucationHeadContact: registeredFields.communucationHeadContact,
    typeOfNGO: registeredFields.typeOfNGO,
    nameOfAccountHolder: registeredFields.nameOfAccountHolder,
    accountNumber: registeredFields.accountNumber,
    ifscCode: registeredFields.ifscCode,
    branch: registeredFields.branch,
    gpayNumber: registeredFields.gpayNumber,
    paytmNumber: registeredFields.paytmNumber,
    profilePic: registeredFields.profilePic,
    facebookId: registeredFields.facebookId,
    instagramId: registeredFields.instagramId,
    title: registeredFields.title,
    bankName: registeredFields.bankName,
    goals: registeredFields.goals,
    certificates :registeredFields.certificates||[]
  });
  const handleNext = () => {
    setPage(page + 1);
  };
  const handlePrevious = () => {
    setPage(page - 1);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (modal) {
      console.log("profile picture", props.profilePictureResponse.results);
      if (props.profilePictureResponse.results) {
        profileDetails.profilePicture =
          props.profilePictureResponse.results.Location;
      }
      setProfileDetails(profileDetails);
      props.loadingMedia(true);
      props.editUser(profileDetails);
      setModal(!modal);
    } else {
      setModal(!modal);
      title = EditProjectMessage;
      image = approveImage;
    }
  };
  const handleChange = (event, formFields) => {
    setProfileDetails((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };

  return (
    <div style={{ marginTop: "5%" }}>
      <Tabs Tabs={profileTabs} tabChange={tabChange} tabValue={tabValue} />
      {modal ? (
        <DialogBox
          submit={handleSubmit}
          image={image}
          title={title}
          modal={modal}
          setModal={setModal}
        />
      ) : (
        ""
      )}
      {tabValue === "Account Information" && page === 1 ? (
        <AccountInformation
          profileDetails={profileDetails}
          handleChange={handleChange}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      ) : (
        ""
      )}
      {tabValue === "Account Information" && page === 2 ? (
        <AccountInformation2
          profileDetails={profileDetails}
          handleChange={handleChange}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          setProfileDetails={setProfileDetails}
        />
      ) : (
        ""
      )}
      {tabValue === "Account Information" && page === 3 ? (
        <AccountInformation3
          profileDetails={profileDetails}
          handleChange={handleChange}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleSubmit={handleSubmit}
          setProfileDetails={setProfileDetails}
        />
      ) : (
        ""
      )}
      {tabValue === "Bank Account Details" ? (
        <BankAccountDetails
          profileDetails={profileDetails}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      ) : (
        ""
      )}
      {tabValue === "Transaction History" ? (
        <TransactionHistory ngoId={registeredFields._id} />
      ) : (
        ""
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profilePictureResponse: state.registerReducer.profilePicture,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (profileDetails) =>
      dispatch({ type: "EDIT_USER", value: profileDetails }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
