import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";

function* listAllTransactionsAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "get",
        url: BASE_URL + "/payment/getAllPaymentToNGO",
        params:{
            ngo_id:request.value
        }
      });
    });
    yield put({ type: "LIST_TRANSACTIONS_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });

  } catch (e) {
    alert("404 status error", e);
  }
}
function* transactionSettleAsync(request) {
  try {
    const { data } = yield call(() => {
      return axios.request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "post",
        url: BASE_URL + "/payment/payRequests",
        data:{
            ngo_id:request.value.ngo_id,
            payment_id :request.value.payment_id
        }
      });
    });
    yield put({ type: "LIST_TRANSACTIONS_ASYNC", value: data });
    yield put({ type: "LOADING_MEDIA", value: false });

  } catch (e) {
    alert("404 status error", e);
  }
}

export function* watchTransactions() {
  yield takeLatest("LIST_TRANSACTIONS", listAllTransactionsAsync);
  yield takeLatest("TRANSACTION_SETTLE", transactionSettleAsync);

}
