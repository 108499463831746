import { Input } from "@mui/material";
import React,{useState} from "react";
import {connect} from "react-redux"
let query={};
const Search = (props) => {
  const [searchValue,setSearchValue] = useState();
  const handleChange = (event) =>{
    console.log("type",props.type)
    query={term:event.target.value,type:props.type,page:props.page}
    props.search(query);
    setSearchValue(event.target.value)
  }
  return (
    <Input
      inputProps={{ style: { fontSize: "1vw" } }}
      disableUnderline={true}
      sx={{
        borderRadius: "31px",
        border: "1px solid #DCDCEB",
        padding: "1% 1% 1% 10%",
        width: "20vw",
        height: "6vh",
        background:"#F9F9F9"
      }}
      value={searchValue}
      onChange={handleChange}
      placeholder="Search"
      className="search"
    ></Input>
  );
};
const mapStateToProps = (state) => {
  return {
    searchResponse: state.projectReducer.search,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    search: (query) =>
      dispatch({ type: "SEARCH", value: query }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
