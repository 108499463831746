const initialState = {
   alltransactions:[]
  };
  
  const transactionReducer = (state = initialState, action) => {
    const newState = { ...state };
  
    switch (action.type) {
      case "LIST_TRANSACTIONS_ASYNC":
        newState.alltransactions = action.value;
        break;
      default:
        return newState;
    }
    return newState;
  };
  
  export default transactionReducer;
  