import { connect } from "react-redux";
import { Card, Typography, Input, Button } from "@mui/material";
import { ngoProfileStyles } from "./ngoProfileStyles";

const BankAccountDetails = (props) => {
  const classes = ngoProfileStyles();
  return (
    <div
      className="project-header3 left"
      style={{ borderTopLeftRadius: "0px" }}
    >
      <form onSubmit={props.handleSubmit} type="submit">
        <Typography className={classes.title}>Name of Bank*</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.bankName}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "bankName");
          }}
        />
        <Typography className={classes.title}>
          Name Of Account holder*
        </Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.nameOfAccountHolder}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "nameOfAccountHolder");
          }}
        />
        <Typography className={classes.title}>Account Number*</Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.accountNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "accountNumber");
          }}
        />
        <Typography className={classes.title}>I.F.S.C Code*</Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.ifscCode}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "ifscCode");
          }}
        />
        <Typography className={classes.title}>Branch*</Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.branch}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "branch");
          }}
        />
        <Typography className={classes.title}>G-Pay number*</Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.gpayNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "gpayNumber");
          }}
        />
        <Typography className={classes.title}>Paytm Number*</Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
          variant="outlined"
          placeholder="Description"
          disableUnderline={true}
          value={props.profileDetails.paytmNumber}
          type="text"
          onChange={(e) => {
            props.handleChange(e, "paytmNumber");
          }}
        />
        <Button
          type="submit"
          onClick={(e) => {
            props.handleSubmit(e);
          }}
          className={classes.NgoSaveButton}
          style={{ width: "15vw", marginLeft: "50vw" }}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    profilePictureResponse: state.registerReducer.profilePicture,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (profileDetails) =>
      dispatch({ type: "EDIT_USER", value: profileDetails }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BankAccountDetails);
