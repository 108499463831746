import { useStyles } from "./loginStyle.js";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import "../login/login.scss";
import loginbckimg from "../../assets/images/Loginview2.png";
import { useNavigate } from "react-router-dom";
import spinner from "../../assets/images/loadingSpinner.json";
import Lottie from "react-lottie";
import logo from "../../assets/images/logo3.png";

import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
const EnterOtp = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [error, setError] = useState(false);

  const [otp, setOtp] = React.useState(new Array(4).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleSubmit = (e) => {
    console.log("enter");
    e.preventDefault();
    props.verifyOtp(otp.join(""));
  };
  useEffect(() => {
    if (props.verifyOtpResponse.message === "OTP is not correct") {
      setError(true);
    } else if (
      props.verifyOtpResponse.message === "OTP Successfully verified"
    ) {
      navigate("/resetPassword", {
        state: { email: props.verifyOtpResponse.email },
      });
    }
  }, [error, props]);

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundImage: "url(" + loginbckimg + ")",
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "cover",
          backgroundSize: "100vw 100vh",
        }}
      ></Box>
      <Box
        sx={{ position: "absolute", left: "35%", top: "8%", maxWidth: "33%" }}
      >
        <img
          width="35%"
          height="35%"
          style={{ margin: "0% 0% 7% 32%" }}
          src={logo}
          alt={logo}
        />
        <Typography
          sx={{ fontWeight: "500", marginBlock: "3%" }}
          align="center"
          variant="h4"
        >
          Enter OTP
        </Typography>
        <Typography align="center" variant="subtitle1">
          A code has been sent to your entered Email ID
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            sx={{ marginTop: "15%", paddingLeft: "20%", paddingRight: "20%" }}
          >
            {otp.map((data, index) => {
              return (
                <TextField
                  type="tel"
                  error={error}
                  required
                  className={classes.loginInput}
                  inputProps={{
                    style: {
                      fontSize: "1.5vw",
                      border: "black",
                      paddingLeft: "40%",
                    },
                    maxLength: 1,
                  }}
                  variant="outlined"
                  key={index}
                  value={data}
                  onChange={(e) => {
                    handleChange(e.target, index);
                    setError(false);
                  }}
                  onFocus={(e) => e.target.select()}
                  fullWidth
                  style={{ margin: "4% 4% 0 0", border: "0" }}
                  disableUnderline={true}
                />
              );
            })}
          </Box>
          <Typography
            sx={{ marginTop: "10%" }}
            align="center"
            variant="subtitle1"
          >
            Didn’t recieve OTP?
            <Link
              onClick={() => {
                // navigate("/forgotPassword");
              }}
              align="center"
              sx={{
                fontWeight: "bold",
                padding: "0 0 0 5px",
                fontSize: "16px",
                cursor: "pointer",
                color: "black",
                textDecoration: "none",
              }}
            >
              Resend OTP
            </Link>
          </Typography>
          {error ? (
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                marginTop: "5%",
                color: "#FA2E2E",
                borderRadius: "55px",
              }}
            >
              {props.verifyOtpResponse.message}
            </Typography>
          ) : (
            ""
          )}
          <Button type="submit" fullWidth className={classes.submitButton}>
            Submit
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    verifyOtpResponse: state.registerReducer.verifyOtp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyOtp: (otp) =>
      dispatch({
        type: "VERIFY OTP",
        value: otp,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnterOtp);
