import Lottie from "react-lottie";
import { profileTransactionHistory } from "../../constants/constants";
import reportIcon from "../../assets/images/reportIcon.png";
import { connect } from "react-redux";
import gifReader from "../../assets/images/loading.json";
import {
  colorChange,
  approveSettlementMessage,
} from "../../constants/constants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import more from "../../assets/images/more.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import approveImage from "../../assets/images/approve-image.png";
import DialogBox from "../dialogBox/confirmationDialog";
import React from "react";
import {
  Box,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableContainer,
  TableBody,
  ListItemIcon,
  Button,
} from "@mui/material";
import { ngoProfileStyles } from "./ngoProfileStyles";
import { useEffect } from "react";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};
let status = "";
let title = "";
let image = "";
const TransactionHistory = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [item, setItem] = useState([]);
  console.log("item", item);
  const open = Boolean(anchorEl);
  useEffect(() => {
    props.loadingMedia(true);
    props.listAllTransactions(props.ngoId);
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSettle = (e) => {
    if (modal) {
      console.log("entered", item);
      props.donationSettle(item.ngo_id, item.payment_id);
      props.loadingMedia(true);
      let timer = setTimeout(() => {
        props.listAllTransactions(item.ngo_id);
        // navigate("/donationHistory");
        setModal(!modal);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      title = approveSettlementMessage;
      image = approveImage;
      status = "Settle";
      setModal(!modal);
    }
  };
  const classes = ngoProfileStyles();
  return props.loadingMediaResponse ? (
    <Lottie options={defaultOptions} height={50} width={50} />
  ) : (
    <div className="project-header3" style={{ borderRadius: "50px" }}>
      <DialogBox
        status={status}
        submit={status === "Settle" ? handleSettle : ""}
        image={image}
        title={title}
        modal={modal}
        setModal={setModal}
      />
      <Button
        className={classes.genreport}
        sx={{ position: "relative", left: "63vw" }}
        variant="outlined"
        startIcon={<img src={reportIcon} alt={reportIcon} />}
      >
        Generate Report
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "#F5F5F5" }}>
              {profileTransactionHistory &&
                profileTransactionHistory.map((heading, index) => {
                  return (
                    <TableCell sx={{ fontSize: "0.9vw" }} key={index}>
                      {heading}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          {props.transactionResponse.results &&
            props.transactionResponse.results.map((data, index) => {
              return (
                <TableBody>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                    align="left"
                  >
                    <strong>{data.amount}</strong>
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                    align="left"
                  >
                    {data.createdDate}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                    align="left"
                  >
                    {data.blog_title}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                    align="left"
                  >
                    {data.method}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "uppercase", fontSize: "0.9vw" }}
                    align="left"
                  >
                    <Box
                      sx={{
                        background: colorChange(data.status)[0],
                        borderRadius: "30px",
                        textTransform: "uppercase",
                        color: "#ffffff",
                        textAlign: "center",
                        padding: "3% 0% 3% 0%",
                        alignItems: "center",
                        display: "flex",
                        width: "9vw",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "100%",
                          marginRight: "5%",
                          marginLeft: "5%",
                        }}
                        src={colorChange(data.status)[1]}
                        alt={colorChange(data.status)[1]}
                      />
                      {data.status === "created" ? "pending" : data.status}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                      src={more}
                      alt={more}
                    />
                    <Menu
                      className={classes.menu}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      autoFocus={false}
                    >
                      <MenuItem
                        sx={{ fontSize: "1vw" }}
                        onClick={(e) => {
                          setAnchorEl(null);
                          setItem(data);
                          handleSettle(e);
                        }}
                      >
                        <ListItemIcon>
                          <img
                            width={30}
                            height={30}
                            src={approveImage}
                            alt={approveImage}
                          />
                        </ListItemIcon>
                        Request
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableBody>
              );
            })}
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionResponse: state.transactionReducer.alltransactions,
    loadingMediaResponse: state.projectReducer.loadingMedia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAllTransactions: (ngoId) =>
      dispatch({ type: "LIST_TRANSACTIONS", value: ngoId }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    donationSettle: (ngoId, paymentId) =>
      dispatch({
        type: "TRANSACTION_SETTLE",
        value: { ngo_id: ngoId, payment_id: paymentId },
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
