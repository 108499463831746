import React, { useState } from "react";
import "./registration.scss";
import logo from "../../assets/images/logo3.png";
import loginbckimg from "../../assets/images/Loginview2.png";
import { Box, Typography, TextField, Button } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../login/loginStyle";
const Registration = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    contactNumber: "",
    registrationNumber: "",
    password: "",
  });
  const handleChange = (event, formFields) => {
    setUserData((prevState) => ({
      ...prevState,
      [formFields]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    props.registerUser(userData);
    console.log("props", props.registerResponse);
    if (props.registerResponse) {
      navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          backgroundImage: "url(" + loginbckimg + ")",
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "cover",
          backgroundSize: "100vw 100vh",
        }}
      ></Box>
      <Box sx={{ position: "absolute", left: "35%", top: "8%", width: "33vw",paddingBottom:"5%" }}>
        <img
          width="35%"
          height="35%"
          style={{ margin: "0% 0% 7% 32%" }}
          src={logo}
          alt={logo}
        />
        <Typography
          sx={{ fontWeight: "500", marginBlock: "3%" }}
          align="center"
          variant="h4"
        >
          Register Individual Account!
        </Typography>
        <Typography align="center" variant="subtitle1">
          For the purpose of industry regulation, your details are required.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.name}
            onChange={(e) => {
              handleChange(e, "name");
            }}
            placeholder="Name *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="email"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.email}
            onChange={(e) => {
              handleChange(e, "email");
            }}
            placeholder="Email ID *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="text"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.address}
            onChange={(e) => {
              handleChange(e, "address");
            }}
            placeholder="Address *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="text"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.city}
            onChange={(e) => {
              handleChange(e, "city");
            }}
            placeholder="City *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="text"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.state}
            onChange={(e) => {
              handleChange(e, "state");
            }}
            placeholder="State *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="number"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.contactNumber}
            onChange={(e) => {
              handleChange(e, "contactNumber");
            }}
            placeholder="Contact Number *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="text"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.registrationNumber}
            onChange={(e) => {
              handleChange(e, "registrationNumber");
            }}
            placeholder="Registration Number *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <TextField
            type="password"
            required
            className={classes.loginInput}
            inputProps={{ style: { fontSize: "1vw", border: "black" } }}
            variant="outlined"
            value={userData.password}
            onChange={(e) => {
              handleChange(e, "password");
            }}
            placeholder="Password *"
            fullWidth
            style={{ marginTop: 20 }}
            disableUnderline={true}
          />
          <Button type="submit" fullWidth className={classes.loginButton}>
            Register Account
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    registerResponse: state.registerReducer.results,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (formFields) =>
      dispatch({ type: "REGISTER", value: formFields }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Registration);
