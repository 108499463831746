import React from "react";
import Header from "../../components/header/header";
import ProfileContainer from "../../components/profileContainer/profileContainer";
import Navbar from "../../components/navbar/navbar";
import DashboardHomeContainer from "../../components/dashboardHomeContainer/dashboardHomeContainer";
import { connect } from "react-redux";
import gifReader from "../../assets/images/loading.json";
import Lottie from "react-lottie";
import {Card} from "@mui/material"
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: gifReader,
  renderer: "svg",
};


class Profile extends React.Component {
  componentDidMount(){
    if(!this.props.loginResponse.results) {
      this.props.auth();
    }
  }
  

  render() {
    return (
      <div>
        <Navbar active="Profile" />
        <Header heading="Profile" />
        {this.props.loginResponse.results ? (
          <React.Fragment>
               <Card
          sx={{
            margin: "0% 0% 0% 11.7%",
            padding: "2% 3% 9% 4%",
            border: "1px solid #E5E5E5",
            borderRadius: "15px",
          }}
        >
              <DashboardHomeContainer />
              <ProfileContainer registeredFields={this.props.loginResponse.results.userData} />
            </Card>
          </React.Fragment>
        ) : (
          <Lottie options={defaultOptions} height={500} width={500} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginResponse: state.loginReducer.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auth: () => dispatch({ type: "AUTH" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
