import { spawn } from "redux-saga/effects";
import { watchProject } from "./project";
import { watchLogin } from "./login";
import { watchRegister } from "./register";
import { watchTransactions } from "./transactions";

export default function* rootSaga() {
  yield spawn(watchLogin);
  yield spawn(watchRegister);
  yield spawn(watchProject);
  yield spawn(watchTransactions);
}
