import React, { useEffect, useState } from "react";
import Search from "../search/search";
import "./dashboardDetailsContainer.scss";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectStatusContainer from "./projectStatusContainer";
import Tabs from "../tabs/tabs";
import { useStyles } from "./dashboardDetailsStyle";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import {
  Box,
  Card,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableContainer,
  TableBody,
} from "@mui/material";

import {
  dashboardTabs,
  dashboardProjectHeading,
} from "../../constants/constants";

const DashboardDetailsContainer = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [heading, setHeading] = useState([]);
  const [tabValue, setTabValue] = useState("");
  const location = useLocation();
  const tabChange = (tab) => {
    setTabValue(tab);
    if (tab === "Overall") {
      props.selectedTab("Overall");
      props.projectTabAction("Overall");
      props.setPage(1);
      props.setCount(props.projectListResponse.totalCount);
    }
    if (tab === "Finished") {
      props.selectedTab("Completed");
      props.projectTabAction("Finished");
      props.setPage(1);
      props.setCount(props.loginResponse.results.allBlogStatus.completed);
    }
    if (tab === "On Progress") {
      props.selectedTab("In progress");
      props.projectTabAction("On Progress");
      props.setPage(1);
      props.setCount(props.loginResponse.results.allBlogStatus.inProgress);
    }
    if (tab === "Closing Soon") {
      props.projectTabAction("Closing Soon");
      props.selectedTab("Closing");
      props.setPage(1);
      props.setCount(props.loginResponse.results.allBlogStatus.closing);
    }
    if (tab === "Rejected") {
      props.projectTabAction("Rejected");
      props.selectedTab("Rejected");
      props.setPage(1);
      props.setCount(props.loginResponse.results.allBlogStatus.rejected);
    }
  };

  // const colorChange = (status) => {
  //   if (status === "Completed") {
  //     return "linear-gradient(89.63deg, #64A91F 1.41%, #79C82A 97.96%)";
  //   }
  //   if (status === "In progress") {
  //     return "linear-gradient(270deg, #FAC11C 0%, #DDA400 100%)";
  //   }
  //   if (status === "Closing") {
  //     return "#679FF4";
  //   }
  //   if (status === "rejected") {
  //     return "linear-gradient(89.8deg, #DC4734 -4.18%, #EE503C 99.75%)";
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    if (props.actionResponse === "Total Projects") {
      setTabs(dashboardTabs);
      setHeading(dashboardProjectHeading);
      setTabValue(props.projectTabResponse || tabs[0]);
      setDashboardData(props.dashboardData);
    }
  }, [location, tabs, props, navigate]);

  return (
    <React.Fragment>
      {tabs ? (
        <Tabs Tabs={tabs} tabChange={tabChange} tabValue={tabValue} />
      ) : (
        ""
      )}
      <Card
        sx={{
          margin: "0% 0% 0% 0%",
          border: "1px solid #EBEBEB",
          borderRadius: "21px",
          borderTopLeftRadius: "0px",
        }}
      >
        <Box className={classes.searchContainer}>
          <Search page={props.page} type="blogs" />
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: "#F5F5F5" }}>
                {heading &&
                  heading.map((header, index) => {
                    return (
                      <TableCell
                        align="left"
                        sx={{ fontSize: "0.9vw" }}
                        key={index}
                      >
                        {header}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardData
                ? dashboardData.map((data, index) => {
                    if (props.actionResponse === "Total Projects") {
                      if (
                        data.diffDays === props.selectedResponse ||
                        tabValue === "Overall"
                      ) {
                        return (
                          <ProjectStatusContainer key={index} data={data} />
                        );
                      } else {
                        return false;
                      }
                    }
                    return false;
                  })
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Box
        sx={{ display: "flex", flexDirection: "row-reverse", marginTop: "3%" }}
      >
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(props.count / 10)}
            siblingCount={1}
            boundaryCount={1}
            page={props.page}
            onChange={props.handleChange}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    projectListResponse: state.projectReducer.results,
    loadingMediaResponse: state.projectReducer.loadingMedia,
    actionResponse: state.projectReducer.dashboardAction,
    loginResponse: state.loginReducer.results,
    projectTabResponse: state.projectReducer.projectTab,
    selectedResponse: state.projectReducer.selectedTab,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    listAllProjects: (page, type) =>
      dispatch({ type: "PROJECT_LIST", value: { page: page, type: type } }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    dashboardAction: (action) =>
      dispatch({ type: "DASHBOARD_ACTION", value: action }),
    projectTabAction: (action) =>
      dispatch({ type: "PROJECT_TAB", value: action }),
    selectedTab: (action) => dispatch({ type: "SELECTED_TAB", value: action }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDetailsContainer);
