import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  "&.MuiPaper-root": {
    overflowY: "hidden",
  },
  newButton: {
    background: "#F9C11D",
    borderRadius: "10px",
    minWidth: "max-content",
    marginLeft:"3%",
    padding: "0.5% 1.2% 0.5% 1.2%",
    color:"black",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
  },
  searchContainer: {
    marginLeft: "1%",
    padding: "2% 3% 2% 2%",
    display: "flex",
    alignItems: "center",
    position:"relative"
  },
  projectRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FEF6DD",
    },
  },
  menu: {
    "& .MuiMenu-paper": {
      boxShadow: "none",
      border: "1px solid #EBEBEB",
    },
  },
  listTitleContainer: {
    display: "flex",
    alignItems: "center",
    background: "#F5F5F5",
    padding: "1% 3% 1% 3%",
    position:"relative"
  },
  viewButton: {
    background: "#FAC11C",
    borderRadius: "6px",
    width:"5vw",
    padding: "0.5% 2% 0.5% 2%",
    marginLeft:"87%",
    fontWeight:"bold",
    fontSize:"0.9vw",
    position:"absolute",
    "&:hover": {
      backgroundColor: "#F9C11D",
      opacity: 0.9,
    },
    color:"black"
  },
});
