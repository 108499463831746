import { makeStyles } from "@mui/styles";

export const ngoProfileStyles = makeStyles({
  block: {
    background: "#F24E3A",
    borderRadius: "22px",
    color: "#FFFFFF",
    "&:hover": {
      opacity: 0.9,
      background: "#F24E3A",
    },
    marginTop: "5%",
  },
  dialog: {
    "& .MuiPaper-root": {
      width: "50vw",
      borderRadius: "30px",
      background: "#ffffff",
    },
  },
  list: {
    display: "flex",
    padding: "3% 2% 3% 2%",
    position: "relative",
    alignItems: "center",
  },
  tab: {
    textTransform: "initial",
    fontSize: "0.9vw",
    boxShadow: "none",
    margin: "0% 4% 0% 0%",
    padding: "0%",
    width: "max-content",
    color: "#362816",
    minWidth: "0px",
    "&.Mui-selected": {
      color: "#362816",
      fontWeight: "bold",
    },
  },
  tabContainer: {
    borderBottom: "1px solid #EBEBEB",
  },
  button: {
    boxShadow: "none",
    color: "white",
    margin: "5% 2% 3% 0%",
    borderRadius: "39px",
    textTransform: "initial",
    fontSize: "0.9vw",
    fontWeight: "bold",
    width: "7vw",
    padding: "1% 1% 1% 1%",
  },
  approve: {
    background: "#61B40E",
    "&:hover": {
      opacity: 0.9,
      background: "#61B40E",
    },
  },

  delete: {
    background: "#FFFFFF",
    border: "1px solid #9CA0AF",
    color: " #23190C",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#FAC11C",
      height: "0.4vh",
    },
  },
  text: {
    fontFamily: 'Poppins',
    background: "#FFFFFF",
    border: "1px solid #CACACA",
    borderRadius: 6,
    color: "black",
    fontSize: "1vw",
    fontWeigth:"400",
    textTransform: "initial",
    padding: "5%",
    width:"30vw"
  },
  genreport: {
    background: "#FFFFFF",
    border: "1px solid #CACACA",
    borderRadius: 6,
    color: "black",
    fontSize: "1vw",
    textTransform: "initial",
    width:"15vw",
    height:"5vh",
    margin: "1% 0% 1% 0%"
  },
  input: {
    padding: "1% 2% 1% 2%",
    margin: "1% 0% 1% 0%",
    width:"20vw"
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: "0.9vw",
    marginTop: "1%",
    fontWeigth:"400",
  },
  othertext: {
    fontSize: "0.9vw",
    marginTop: "2%",
    fontWeight: "bold",
  },
  NgoSaveButton: {
    background: "#FAC11C",
    borderRadius: "12px",
    color: "black",
    marginBottom: "5%",
    "&:hover": {
      background: "#FAC11C",
      opacity: 0.9,
    },
  },
  menu:{
    "& .MuiPopover-paper":{
      boxShadow:"0px 1px 5px rgba(0, 0, 0, 0.1)",
      border:"1px solid #EBEBEB",
    },
  },
  donorSaveButton: {
    background: "#FAC11C",
    borderRadius: "12px",
    boxSizing: "border-box",
    width: "15vw",
    color: "black",
    "&:hover": {
      background: "#FAC11C",
      opacity: 0.9,
    },
  },
  donorCancelButton: {
    border: "1px solid #DCDCEB",
    background: "#FFFFFF",
    width: "15vw",
    color: "black",
    marginBottom: "7%",
  },
});
