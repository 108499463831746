import {
  Card,
  Box,
  Grid,
  Typography,
  styled,
  Paper,
  Input,
  InputAdornment,
  Button,
  IconButton,
  Stack
} from "@mui/material";
import { ngoProfileStyles } from "./ngoProfileStyles";
import editImg from "../../assets/images/editImg.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import { connect } from "react-redux";
import profile from "../../assets/images/profile1.png";
import spinner from "../../assets/images/loadingSpinner.json";
import Lottie from "react-lottie";
import "./profileContainer.scss";
import uploadImg2 from "../../assets/images/upldimg2.png";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useEffect } from "react";
import useNavigate from "react-router-dom";
import { pdfjs } from "react-pdf";
let docs = [{ uri: "", fileType: "" }];
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  renderer: "svg",
};
let upload1Type = "";
let upload2Type = "";
document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
});
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
window.Buffer = window.Buffer || require("buffer").Buffer;
const AccountInformation3 = (props) => {
  useEffect(() => {
    if (props.upload2Response.Location) {
      if (upload2Type.match("application")) {
        upload2Type = "";
        let newObject = { ...props.profileDetails };
        console.log("profile", newObject);
        newObject.certificates.push(props.upload2Response.Location);
        props.setProfileDetails(newObject);
      }
      props.removeUploadContent();
    } // eslint-disable-next-line
  }, [props, location]); // eslint-disable-next-line

  const classes = ngoProfileStyles();

  const handleUpload = (event, upload) => {
    if (upload === "upload1") {
      props.loadingMedia(true);
      upload1Type = event.target.files[0].type;
      props.upload1(event.target.files[0]);
    } else {
      props.loadingDocs(true);
      upload2Type = event.target.files[0].type;
      props.upload2(event.target.files[0]);
    }
  };

  return (
    <Card sx={{ borderTopLeftRadius: "0px", padding: "1%" }}>
      <Typography
        style={{ fontSize: "1.2vw", fontWeight: "600", marginLeft: "2vw" }}
      >
        Basic details:
      </Typography>
      <Box
        style={{
          display: "grid",
          margin: "5% 0% 1% 0%",
          padding: "0% 0% 0% 3%",
          alignItems: "center",
        }}
      >
        <div id="img_container" style={{position:"relative", display:"-ms-inline-grid",
        }} >
        {props.loadingMediaResponse ? (
          <Lottie   options={defaultOptions} height={50} width={50} />
        ) : (
          <img
            height="150vh"
            width="150vw"
            z-index="-1"
            src={
              props.profilePictureResponse.results
                ? props.profilePictureResponse.results.Location
                : props.profileDetails.profilePic || profile
            }
            alt={
              props.profilePictureResponse.results
                ? props.profilePictureResponse.results.Location
                : props.profileDetails.profilePic || profile
            }
          />
        )}

        {
            <IconButton sx={{position:"relative", zIndex:"9", top:"0",
            left: "-2vw"}} edge="start" color="primary" aria-label="upload picture" component="label" type="file">
            <img   src={editImg} alt={editImg }/>
              <input  hidden accept="image/*" type="file" style={{display:"none"}} onChange={(event)=>{handleUpload(event)} }/>
            </IconButton>
           
      
        }
        </div>
        <Box
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              marginRight: "2vw",
            }}
          >
            <Typography
              className={classes.title}
              sx={{
                justifyContent: "space-between",
                marginRight: "1vw",
              }}
            >
              Communications Head Name:(optional)
            </Typography>
            <Input
              className={`${classes.text} ${classes.input}`}
              inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
              variant="outlined"
              value={props.profileDetails.communicationHeadName}
              onChange={(e) => {
                props.handleChange(e, "communicationHeadName");
              }}
              placeholder=""
              disableUnderline={true}
            />
          </div>
          <div>
            <Typography className={classes.title}>
              Communications Head Contact No:(optional)
            </Typography>

            <Input
              className={`${classes.text} ${classes.input}`}
              inputProps={{ style: { fontSize: "0.9vw", marginLeft: "1vw" } }}
              variant="outlined"
              type="number"
              value={props.profileDetails.communicationHeadContact}
              onChange={(e) => {
                props.handleChange(e, "communicationHeadContact");
              }}
              placeholder=""
              disableUnderline={true}
            />
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            margin: "3% 0% 1% 0%",
            width: "30vw",
            alignItems: "baseline",
          }}
        >
          <Typography className={classes.title}>Basic details:</Typography>
          <div>
            {props.loadingDocResponse ? (
              <Lottie options={defaultOptions} height={50} width={50} />
            ) : (
              ""
            )}
            <input
              placeholder="browse"
              id="inputTag"
              type="file"
              onChange={(event) => {
                handleUpload(event, "upload2");
              }}
            />
          </div>
          {props.profileDetails.certificates &&
            props.profileDetails.certificates.map((file, index) => {
              {
                docs = [
                  {
                    uri: file,
                    fileType: file.substr(
                      file.indexOf(".", file.length - 5) + 1,
                      file.length
                    ),
                  },
                ];
              }
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: "90%",
                      height: "10vh",
                      marginTop: "5%",
                      marginBottom: "15vh",
                    }}
                  >
                    <DocViewer
                      style={{ height: "40vh", marginBottom: "5vh" }}
                      pluginRenderers={DocViewerRenderers}
                      documents={docs}
                    />
                  </Box>
                </Box>
              );
            })}
        </Box>

        {/* <Typography className={classes.title}>Title</Typography>
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          variant="outlined"
          type="text"
          value={props.profileDetails.title}
          onChange={(e) => {
            props.handleChange(e, "title");
          }}
          placeholder=""
          disableUnderline={true}
        /> */}

        <Typography className={classes.title}>Social Media Links </Typography>

        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          startAdornment={
            <InputAdornment position="start">
              <img src={facebook} alt={facebook} />
            </InputAdornment>
          }
          variant="outlined"
          value={props.profileDetails.facebookId}
          onChange={(e) => {
            props.handleChange(e, "facebookId");
          }}
          placeholder="Facebook Id"
          disableUnderline={true}
        />
        <Input
          className={`${classes.text} ${classes.input}`}
          inputProps={{ style: { fontSize: "0.9vw" } }}
          startAdornment={
            <InputAdornment position="start">
              <img src={instagram} alt={instagram} />
            </InputAdornment>
          }
          variant="outlined"
          value={props.profileDetails.instagramId}
          onChange={(e) => {
            props.handleChange(e, "instagramId");
          }}
          placeholder="Instagram Id"
          disableUnderline={true}
        />
        <div display="inline">
          <Button
            type="submit"
            onClick={(e) => {
              props.handlePrevious(e);
            }}
            className={classes.NgoSaveButton}
            style={{ width: "15vw", marginLeft: "0vw", marginTop: "5%" }}
          >
            Back
          </Button>
          <Button
            className={classes.NgoSaveButton}
            type="submit"
            onClick={props.handleSubmit}
            style={{ width: "15vw", marginLeft: "42vw", marginTop: "5%" }}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    profilePictureResponse: state.registerReducer.profilePicture,
    loadingMediaResponse: state.projectReducer.loadingMedia,
    upload1Response: state.projectReducer.upload1,
    upload2Response: state.projectReducer.upload2,
    loadingDocResponse: state.projectReducer.loadingDoc,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profileUpload: (file) => dispatch({ type: "PROFILE_UPLOAD", value: file }),
    loadingMedia: (loader) =>
      dispatch({ type: "LOADING_MEDIA", value: loader }),
    upload1: (file) => dispatch({ type: "UPLOAD1", value: file }),
    upload2: (file) => dispatch({ type: "UPLOAD2", value: file }),
    loadingDocs: (loader) => dispatch({ type: "LOADING_DOC", value: loader }),
    removeUploadContent: () =>
      dispatch({ type: "REMOVE_UPLOAD_CONTENT", value: "" }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformation3);
